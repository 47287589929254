import { QImg as _component_q_img } from 'quasar/src/index.dev.js';
import { QBtn as _component_q_btn } from 'quasar/src/index.dev.js';
import { Intersection as _directive_intersection } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "hero-unit" }
const _hoisted_2 = { class: "container full-height-container vertical" }
const _hoisted_3 = { class: "flex-vgap" }
const _hoisted_4 = { class: "flex-hgap" }
const _hoisted_5 = { class: "hero-unit" }
const _hoisted_6 = { class: "container full-height-container" }
const _hoisted_7 = { class: "flex-vgap" }
const _hoisted_8 = { class: "flex-hgap" }
const _hoisted_9 = { class: "hero-unit" }
const _hoisted_10 = { class: "container full-height-container" }
const _hoisted_11 = { class: "flex-vgap" }
const _hoisted_12 = { class: "flex-hgap" }

import { computed } from 'vue'
import { useApi } from '@/web/client/utils/useApi'
import { useUserStore } from '@/web/client/store/User/useUserStore'
import { APP_NAME, APP_DESC } from '@/common/Constants'
import {IntersectionValue as IntersectionValue} from 'quasar/src/index.dev.js';

import examplePreviewImg from '@/web/client/assets/img/example-covers-preview.jpg?rl'
import settingsImg from './img/modern-image-settings.png?rl'
import { useSeoMeta } from '@unhead/vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

useSeoMeta({
    title: APP_NAME,
    description: APP_DESC,
    ogImage: examplePreviewImg.src,
    twitterCard: 'summary_large_image',
})

const userStore = useUserStore()
const user = computed(() => userStore.user)
const { login } = useApi()

const intersectConfig: IntersectionValue = {
    cfg: {
        threshold: [0.25],
    },
    handler: (entry) => {
        if (!entry?.isIntersecting) {
            return true
        }

        entry.target?.classList.add('visible')
        return false
    },
}

return (_ctx: any,_cache: any) => {
  
  const _component_CodeBlock = _resolveComponent("CodeBlock")!
  
  

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("article", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("section", null, [
          _createVNode(_component_q_img, {
            src: _unref(examplePreviewImg).src,
            fit: "cover"
          }, null, 8, ["src"])
        ]),
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_unref(APP_DESC)), 1),
          _createVNode(_component_CodeBlock, {
            code: require('./raw/example-usage.css')
          }, null, 8, ["code"]),
          _createElementVNode("div", _hoisted_4, [
            (user.value)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  color: "secondary",
                  unelevated: "",
                  "no-caps": "",
                  label: "Go to Settings",
                  to: "/settings"
                }))
              : (_openBlock(), _createBlock(_component_q_btn, {
                  key: 1,
                  color: "positive",
                  unelevated: "",
                  "no-caps": "",
                  label: "Log in with MAL to track your lists",
                  onClick: _unref(login)
                }, null, 8, ["onClick"])),
            _createVNode(_component_q_btn, {
              outline: "",
              unelevated: "",
              "no-caps": "",
              color: "white",
              label: "Guide",
              to: "guide"
            })
          ])
        ])
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("article", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("section", null, [
          _createVNode(_component_q_img, {
            src: require('@/web/client/assets/img/example-covers.jpg?size=400').src,
            height: "600",
            fit: "cover"
          }, null, 8, ["src"])
        ]),
        _createElementVNode("section", _hoisted_7, [
          _cache[0] || (_cache[0] = _createElementVNode("h1", null, " Not a developer? ", -1)),
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, " If you're just looking for a Classic list design, try out this example list design ", -1)),
          _createVNode(_component_CodeBlock, {
            code: require('./raw/how-to-use-example-covers.css')
          }, null, 8, ["code"]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_q_btn, {
              outline: "",
              unelevated: "",
              "no-caps": "",
              label: "How to Set Up Example List Design",
              to: "/example"
            })
          ])
        ])
      ])
    ])), [
      [
        _directive_intersection,
        intersectConfig,
        void 0,
        { once: true }
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("article", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("section", null, [
          _createVNode(_component_q_img, {
            src: _unref(settingsImg).src
          }, null, 8, ["src"])
        ]),
        _createElementVNode("section", _hoisted_11, [
          _cache[2] || (_cache[2] = _createElementVNode("h1", null, " This service is only for Classic list designs ", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("h2", null, " Modern list designs already have an option to directly embed cover images into your lists ", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_q_btn, {
              outline: "",
              unelevated: "",
              "no-caps": "",
              label: "Learn More about Modern Templates",
              to: "/classic-vs-modern"
            })
          ])
        ])
      ])
    ])), [
      [
        _directive_intersection,
        intersectConfig,
        void 0,
        { once: true }
      ]
    ])
  ], 64))
}
}

})